.packages-header {
	padding: 20px 14px;
	box-shadow: #24242429 0px 0px 2px;
	background-color: #ffffff;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
}

.package-boxes .content-package {
	width: 430px;
	height: auto;
	min-height: 608px;
	padding: 12px 0;
	background-color: #f7fcff;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	box-shadow: #24242429 0px 0px 2px;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	transition: box-shadow, 0.2s ease-in-out;
	-webkit-transition: box-shadow, 0.2s ease-in-out;
	-moz-transition: box-shadow, 0.2s ease-in-out;
	-ms-transition: box-shadow, 0.2s ease-in-out;
	-o-transition: box-shadow, 0.2s ease-in-out;
}

.package-boxes .content-package:is(:hover) {
	box-shadow: #24242429 0px 12px 24px;

	border: 1px solid var(--second-500);
	min-height: 670px;
}

.package-boxes .content-package .pack-name {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	color: var(--second-500);
	font-weight: 500;
}

.package-boxes .content-package .pack-price-box {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 25px;
}

.package-boxes .content-package span.currency {
	font-size: 16px;
	font-weight: 500 !important;
}

.package-boxes .content-package span.price {
	font-weight: bold;
	font-size: 28px;
}

.package-boxes .content-package span.discount-price {
	font-weight: 400;
	color: var(--black-200);
	font-size: 20px;
	text-decoration: line-through;
}

.package-boxes .content-package span:nth-child(3) {
	display: block;
	width: 100%;
	font-weight: 600;
	font-size: 18px;
	margin-right: 10px;
}

.package-boxes .content-package span {
	color: #011723;
}
.package-boxes .content-package .package-type {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 25px;
	color: var(--second-500);

	display: flex;
	justify-content: center;
	align-items: center;
}

.package-boxes .content-package .package_btn {
	color: #eff9ff;
	width: 100%;
	height: 56px;
	background-color: var(--primary-500);
	font-size: 22px;
	font-weight: 400;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}

.package-boxes .content-package .current_package_btn {
	background-color: transparent;
	color: var(--second-500);
	cursor: auto;
	font-weight: 500;
}

/*  top package */
.package-boxes .top-package {
	min-height: 620px;
	border: 1px solid var(--second-500);
	box-shadow: #24242429 0px 12px 24px;
}

.package-boxes .top-package .pack-name {
	font-size: 26px;
	font-weight: 700;
}
.package-boxes .top-package span.price {
	font-size: 38px;
	font-weight: 800;
}
.package-boxes .top-package .package_btn {
	background-color: var(--second-500) !important;
	color: #fff;
}

/* style for alert Message using  rsuite */
.package-alert .rs-message,
.end_package_period .rs-message {
	width: 100%;
}
.package-alert .rs-message-container,
.end_package_period .rs-message-container {
	display: flex;
	padding: 12px 18px 12px 12px;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
}

.package-alert .rs-message .rs-btn-close,
.end_package_period .rs-message .rs-btn-close {
	z-index: 999;
	position: absolute;
	font-size: 10px;
	padding: 12px;
	left: 12px;
	right: auto;
	top: 3px;
}

.package-alert .rs-message .rs-btn-close svg,
.end_package_period .rs-message .rs-btn-close svg {
	font-size: 16px;
}

.package-alert .rs-message {
	width: 79%;
	position: fixed;
	z-index: 1000;
	top: 92px;
	left: 20px;
}
.end_package_period .rs-message {
	position: absolute;
	top: -65px;
}

.package-alert .rs-message-body,
.end_package_period .rs-message-body {
	white-space: normal;
}

/* style for mobile */
@media (max-width: 768px) {
	.package-boxes .content-package {
		width: 100%;
	}
	.package-alert .rs-message {
		width: 94%;
		top: 86px;
		left: 12px;
	}

	.package-boxes .content-package .package_btn {
		height: 48px;
		font-size: 20px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}
}
