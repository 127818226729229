.logo-header {
	height: 98px;
	padding-top: 21px;
	display: flex;
	align-items: center;
	justify-content: start;
}

.logo-header .box-logo {
	width: 120px;
	height: 100px;
	padding: 8px;
	cursor: pointer;

	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.logo-header .box-logo svg,
.logo-header .box-logo img {
	max-height: 100%;
	max-width: 100%;
}
