.evaluationThePlatform .label {
	background-color: var(--primary-500);
	color: #ffff;
	height: 70px;
	white-space: normal;
	border-radius: 16px 16px 0 0px;
	-webkit-border-radius: 16px 16px 0 0px;
	-moz-border-radius: 16px 16px 0 0px;
	-ms-border-radius: 16px 16px 0 0px;
	-o-border-radius: 16px 16px 0 0px;
}

.preview-valuation {
	text-align: right;
	white-space: normal;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #011723;
}
.preview-valuation span {
	margin-right: 4px;
	text-align: right;
	font-size: 16px;
	font-weight: normal;
	white-space: normal;
	letter-spacing: 0px;
	color: #67747b;
}

.preview-valuation-wrapper {
	height: 180px;
	padding: 18px;
	box-sizing: content-box;
	background: #eeeeef 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #02466a0f;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}
.preview-valuation-wrapper .user-image {
	width: 55px;
	height: 55px;
	padding: 5px;
	border: 2px solid #ddd;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.preview-valuation-wrapper .user-image img {
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}

.preview-valuation-wrapper .evaluation-content {
	white-space: normal !important;
	overflow-y: auto;
	text-align: center;
}
.send-valuation-btn {
	width: 278px;
	height: 60px;
	color: #fff;
	background: #1948a0 0% 0% no-repeat padding-box;

	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
}
.send-valuation-btn:disabled {
	background: #c4c4c4;
}

@media (max-width: 768px) {
	.evaluationThePlatform .label {
		border-radius: 14px 14px 0px 0px;
		-webkit-border-radius: 14px 14px 0px 0px;
		-moz-border-radius: 14px 14px 0px 0px;
		-ms-border-radius: 14px 14px 0px 0px;
		-o-border-radius: 14px 14px 0px 0px;
	}
	.evaluationThePlatform textarea {
		height: 140px !important;
		padding: 20px;
		font-size: 16px;
	}
	.preview-valuation-wrapper .evaluation-content {
		font-size: 16px;
	}

	.evaluationThePlatform .label,
	.preview-valuation,
	.send-valuation-btn {
		font-size: 18px;
	}
	.preview-valuation span {
		margin-right: 0;
	}

	.send-valuation-btn {
		width: 100%;
		height: 45px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
	}
}
