.main-in-box {
	background-color: var(--primary-100);
	position: fixed;
	width: 100%;
	height: 100vh;
	overflow: auto;
	top: 0;
	bottom: 0;
	z-index: 500;
	max-width: 1920px;
}

.main-in-box .all-content {
	min-height: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	gap: 15px;
}

.main-in-box .all-content .right-side {
	flex: 1;
	width: 100%;
	height: 100%;
	max-width: 960px;
}

.main-in-box .all-content .right-side .all {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 40px;
	justify-content: start;
	padding-top: 50px;
	width: 100%;
}

.main-in-box .all-content .right-side .all h2 {
	margin: 0;
	color: var(--primary-500);
	font-size: 28px;
	white-space: normal !important;
}

.main-in-box .all-content .right-side .all .tabs {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}

.main-in-box .all-content .right-side .all .tabs .tab {
	min-width: 190px;
	height: 56px;
	align-items: center;
	color: var(--second-500);
	background-color: transparent;
	display: flex;
	font-size: 18px;
	font-weight: 500;
	justify-content: center;
	letter-spacing: 1px;
	padding: 10px 30px;
	white-space: nowrap;
	border-radius: 32px;
	border: 1px solid var(--second-500);
	cursor: pointer;
	outline: none;
}

.main-in-box .all-content .right-side .all .tabs .tab.active {
	color: #f7fcff;
	background-color: var(--primary-500);
	border-color: var(--primary-500);
}

.main-in-box .all-content .left-side {
	flex: 1;
	background: transparent
		linear-gradient(
			150deg,
			var(--unnamed-color-826fa2) 0%,
			var(--unnamed-color-02466a) 100%
		)
		0% 0% no-repeat padding-box;
	background: transparent linear-gradient(150deg, #6390e3 0%, #1948a0 100%) 0%
		0% no-repeat padding-box;
	opacity: 0.95;
	height: auto;
	min-height: 100%;
	padding-top: 65px !important;
	padding-left: 40px !important;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	position: relative;
	z-index: 22;
	gap: 60px;
	width: 100%;
	max-width: 952px;
}

.main-in-box .all-content .left-side .back {
	width: 100%;
	margin: 0;
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 15px;
	cursor: pointer;
	color: #ffffff;
	font-size: 1.1rem;
	font-weight: 500;
	margin-bottom: 40px;
}

.main-in-box .all-content .left-side .over-info {
	width: 100%;
	text-align: left;
	padding-left: 118px;
}

.main-in-box .all-content .left-side .info-svg {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column-reverse;
	gap: 40px;
	margin-bottom: 100px;
}

.main-in-box .all-content .left-side .info-svg h1 {
	margin: 0;
	color: #f7fcff;
	font-size: 94px;
	line-height: 94px;
	font-weight: 600;
	white-space: normal !important;
}

.main-in-box .all-content .left-side .info-svg h4 {
	margin: 0;
	color: #f7fcff;
	font-size: 50px;
	line-height: 54px;
	font-weight: 400;
	white-space: normal !important;
}

@media (min-width: 1200px) {
	.main-in-box .all-content .left-side,
	.main-in-box .all-content .right-side {
		padding: 0 60px;
	}
}

@media (min-width: 992px) {
	.main-in-box .all-content .left-side,
	.main-in-box .all-content .right-side {
		padding: 0 40px;
	}
}

@media (max-width: 992px) {
	.main-in-box .all-content {
		flex-direction: column-reverse;
	}

	.main-in-box .all-content .right-side {
		max-width: 100%;
		padding: 1rem;
	}

	.logo-header {
		justify-content: right;
	}

	.main-in-box .all-content .right-side .all {
		gap: 30px;
	}

	.main-in-box .all-content .right-side .all h2 {
		font-size: 1.6rem;
		line-height: 2rem;
	}

	.main-in-box .all-content .right-side .all .tabs {
		width: 100%;
		overflow-x: auto;
	}

	.main-in-box .all-content .right-side .all .tabs .tab {
		min-width: 160px;
		height: 46px;
		padding: 10px 20px;
		font-size: 1rem;
		font-weight: 500;
	}

	.main-in-box .all-content .left-side .back {
		font-size: 1.3rem;
		margin-bottom: 0;
	}

	.main-in-box .all-content .left-side {
		padding: 4rem !important;
	}

	.main-in-box .all-content .left-side .over-info {
		padding-left: 0;
	}

	.main-in-box .all-content .left-side .info-svg h1 {
		font-size: 4rem;
		line-height: 5rem;
	}

	.main-in-box .all-content .left-side .info-svg h4 {
		font-size: 2.75rem;
		line-height: 3rem;
	}
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
	.main-in-box .all-content .right-side {
		max-width: 100%;
		padding: 0.5rem;
	}

	.logo-header {
		justify-content: right;
	}

	.main-in-box .all-content .right-side .all {
		gap: 20px;
		margin-bottom: 80px;
	}

	.main-in-box .all-content .right-side .all h2 {
		font-size: 1.1rem;
		line-height: 1.5rem;
	}

	.main-in-box .all-content .right-side .all .tabs {
		justify-content: flex-start;
		overflow-x: auto;
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
		padding-bottom: 12px;
		gap: 5px;
	}
	.main-in-box .all-content .right-side .all .tabs::-webkit-scrollbar {
		width: 4px;
	}

	.main-in-box .all-content .right-side .all .tabs .tab {
		scroll-snap-align: start;
		display: inline-block;
		min-width: max-content;
		height: 38px;
		padding: 8px 18px;
		font-size: 14px;
		font-weight: 400;
	}

	.main-in-box .all-content .left-side .back {
		font-size: 1.3rem;
		margin-bottom: 0;
	}

	.main-in-box .all-content .left-side {
		padding: 2rem !important;
	}

	.main-in-box .all-content .left-side .over-info {
		padding-left: 0;
	}

	.main-in-box .all-content .left-side .info-svg h1 {
		font-size: 3rem;
		line-height: 4rem;
	}

	.main-in-box .all-content .left-side .info-svg h4 {
		font-size: 1.75rem;
		line-height: 2rem;
	}
}
