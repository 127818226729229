.Madfou3Modal-overlay * {
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.Madfou3Modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 5555;
	overflow-y: auto;

	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.Madfou3Modal {
	position: relative;
	top: 20%;
	background: white;
	padding: 60px 40px 40px;
	border-radius: 8px;
	max-width: 630px;

	margin: 0 15px;
}

.Madfou3Modal .closebtn {
	background: transparent;
	position: absolute;
	top: 20px;
	right: 20px;
	line-height: 1;
}

.Madfou3Modal .closebtn svg {
	fill: #000;
}

.Madfou3Modal .closebtn:hover svg {
	fill: #b6b1c4;
	transform: rotate(360deg);
}

.Madfou3Modal .note {
	color: #909090;
	font-size: 18px;
	font-weight: 600;
	line-height: 1.6;
	margin: 0 0 50px;
	display: block;
	text-wrap: wrap;
	text-align: center;
}

.Madfou3Modal-form label {
	display: block;
	line-height: 1;
	margin-bottom: 12px;
	font-weight: 600;
	font-size: 18px;
	color: #909090;
}

.Madfou3Modal-form input,
.Madfou3Modal-form .store-info-phonenumber {
	width: 100%;
	border: 2px solid #ddd !important;
	line-height: 50px;
	height: 50px;
	padding: 0 15px;
	font-size: 17px;
	font-weight: 500;
	border-radius: 50px;
	margin-bottom: 30px;
}
.Madfou3Modal-form .store-info-phonenumber:hover,
.Madfou3Modal-form input:is(:hover, :focus) {
	border-color: #b6b1c4 !important;
}

.store-info-phonenumber input,
.store-info-phonenumber input:is(:hover, :focus) {
	all: unset !important;
	direction: ltr;
	text-align: end;
	width: 90% !important;
	line-height: 50px;
	height: 100%;
	font-size: 17px;
	font-weight: 500;
}
.store-info-phonenumber span {
	display: inline-block;
	width: 10%;
	margin-left: 20px;
}

.Madfou3Modal-form .btn {
	width: 100%;
	height: 50px;
	border-radius: 50px !important;
	background-color: #b6b1c4;
	font-size: 1rem;
	font-weight: 500;
	border-radius: 6px;
	border: none;
	outline: none;
	color: #fff;
	transition: all 0.3s ease-in-out;
}

.Madfou3Modal-form .btn:hover,
.Madfou3Modal-form .btn:focus {
	background-color: #02466a;
}

.showPassword {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 20px;
	cursor: pointer;
}
.showPassword svg {
	fill: #909090;
	width: 25px;
}
.showPassword:hover svg {
	fill: #b6b1c4;
}

@media (max-width: 768px) {
	.Madfou3Modal {
		top: 14%;
	}

	.Madfou3Modal {
		padding: 60px 20px 40px;
	}
}
