.password-field {
	position: relative;
}

.password-field .show-password-icon {
	position: absolute;
	top: 60px;
	left: 20px;
	transform: translateY(-50%);
	cursor: pointer;
}

.password-field .wrong-text {
	color: #ff3b3b;
	font-size: 14px;
}

@media (max-width: 768px) {
	.password-field .show-password-icon {
		top: 55px !important;
	}
}
